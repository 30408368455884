import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

import Login from 'app/modules/login/login';
import Register from 'app/modules/account/register/register';
import Activate from 'app/modules/account/activate/activate';
import PasswordResetInit from 'app/modules/account/password-reset/init/password-reset-init';
import PasswordResetFinish from 'app/modules/account/password-reset/finish/password-reset-finish';
import Logout from 'app/modules/login/logout';
import Welcome from 'app/modules/welcome/welcome';
import Home from 'app/modules/home/home';
import Profile from 'app/modules/profile/index';
import Entities from 'app/entities';
import PrivateRoute from 'app/shared/auth/private-route';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import PageNotFound from 'app/shared/error/page-not-found';
import { AUTHORITIES } from 'app/config/constants';
import documentverified, { DocumentVerified } from './modules/documentverified';
import { NotFound } from './entities/document/NotFound';
import documentverifiedprivate from './modules/documentverifiedprivate';

const Account = Loadable({
  loader: () => import(/* webpackChunkName: "account" */ 'app/modules/account'),
  loading: () => <div>loading ...</div>,
});

const Admin = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "administration" */ 'app/modules/administration'
    ),
  loading: () => <div>loading ...</div>,
});

const Routes = (props) => (
  <div
    className="view-routes p-0"
    style={{ overflowY: 'auto', background: '#F5F5F5 !important' }}
  >
    <Switch>
      <ErrorBoundaryRoute path="/login" component={Login} />
      <ErrorBoundaryRoute path="/logout" component={Logout} />
      <ErrorBoundaryRoute path="/account/register" component={Register} />
      <ErrorBoundaryRoute path="/account/activate/:key?" component={Activate} />
      <ErrorBoundaryRoute
        path="/account/reset/request"
        component={PasswordResetInit}
      />
      <ErrorBoundaryRoute
        path="/account/reset/finish/:key?"
        component={PasswordResetFinish}
      />

      <PrivateRoute
        path="/admin"
        component={Admin}
        hasAnyAuthorities={[AUTHORITIES.ADMIN]}
      />
      <PrivateRoute
        path="/account"
        component={Account}
        hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.USER]}
      />
      <PrivateRoute
        path="/profile"
        component={Profile}
        hasAnyAuthorities={[AUTHORITIES.USER]}
      />
      <ErrorBoundaryRoute path="/" exact component={Welcome} />
      <ErrorBoundaryRoute path="/dashboard" exact component={Home} />
      <ErrorBoundaryRoute path="/404" exact component={NotFound} />
      {props.isAuthenticated ? (
        <ErrorBoundaryRoute
          path="/document/verified/:id"
          exact
          component={documentverifiedprivate}
        />
      ) : (
        <ErrorBoundaryRoute
          path="/document/verified/:id"
          exact
          component={documentverified}
        />
      )}

      <PrivateRoute
        path="/"
        component={Entities}
        hasAnyAuthorities={[
          AUTHORITIES.ADMIN,
          AUTHORITIES.CS,
          AUTHORITIES.OE,
          AUTHORITIES.KYC,
          AUTHORITIES.INTERNAL_AUDIT,
          AUTHORITIES.ADMIN_OOA3,
          AUTHORITIES.IT_SUPPORT,
        ]}
      />
    </Switch>
  </div>
);

export default Routes;
