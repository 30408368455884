import 'react-toastify/dist/ReactToastify.css';
import './../content/css/loading.css';
import './../content/css/miraeloading.css';
import '../../../../scss/app.scss';
//import '../../../../scss/bootstrap.scss';
import '../../../../scss/icons.scss';
import { Chart, ArcElement } from 'chart.js';

import React, { useEffect, useState } from 'react';
import { connect, useStore } from 'react-redux';
import { Card, CardGroup, Nav, NavLink, NavItem, Col, Row } from 'reactstrap';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { hot } from 'react-hot-loader';

import { IRootState } from 'app/shared/reducers';
import { getSession } from 'app/shared/reducers/authentication';
import { getProfile } from 'app/shared/reducers/application-profile';
import { setLocale } from 'app/shared/reducers/locale';
import Header from 'app/shared/layout/header/header';
import Footer from 'app/shared/layout/footer/footer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import ErrorBoundary from 'app/shared/error/error-boundary';
import { AUTHORITIES } from 'app/config/constants';
import AppRoutes from 'app/routes';

import SideBar from 'app/shared/layout/sidebar/sidebar';
import Loginx from 'app/modules/login/loginx';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import HeaderPublic from 'app/shared/layout/header-public/header';
import loginx from 'app/modules/login/loginx';
import documentverified from './modules/documentverified';
import NotFound from './entities/document/NotFound';
import useWindowDimensions from './utils/ScreenDimesions';
import HeaderMobileNew from './shared/layout/header/header-mobile';
import documentverifiedprivate, {
  DocumentVerifiedPrivate,
} from './modules/documentverifiedprivate';
import HomeNav from './component/bottomnav/Homenav';
import HistoryNav from './component/bottomnav/HistoryNav';
import NotifNav from './component/bottomnav/NotifNav';
import ProfileNav from './component/bottomnav/ProfileNav';
import PlusNav from './component/bottomnav/PlusNav';

const baseHref = document
  .querySelector('base')
  .getAttribute('href')
  .replace(/\/$/, '');

export interface IAppProps extends StateProps, DispatchProps {}

export const App = (props: IAppProps) => {
  const { height, width } = useWindowDimensions();
  const [isOpen, setOpen] = useState<any>(false);
  let path = window.location.pathname;

  useEffect(() => {
    props.getSession();
    // props.getProfile();
  }, []);

  const paddingTop = '60px';

  const changeOpen = () => {
    setOpen(!isOpen);
  };

  if (props.loading) {
    return (
      <div id="">
        <div className="centerUp">
          <img src="./content/images/logoloading.png" />

          <div className="centerLoadingBar meter">
            <span className="progress"></span>
          </div>
        </div>
        <img src="./content/images/supportby.png" className="centerDown" />
      </div>
    );
  } else {
    return (
      <div
        className=""
        style={
          props.isAuthenticated
            ? { height: '100vh', background: '#fff' }
            : { height: '100%', background: '#fff' }
        }
      >
        <Router basename={baseHref}>
          <div
            className="app-container pb-5 h-100"
            style={{
              overflow: 'hidden',
              overflowY: 'scroll',
              paddingTop: '60px',
            }}
          >
            <ToastContainer
              position={toast.POSITION.TOP_LEFT}
              className="toastify-container"
              toastClassName="toastify-toast"
            />
            {/* { props.isAuthenticated ?  : <></> } */}
            <div
              className={
                width < 426 ? 'view-container pb-5' : 'view-container pb-5'
              }
              id="app-view-container"
            >
              <>
                {props.isAuthenticated ? (
                  <SideBar toggle={true} isOpen={isOpen} />
                ) : (
                  <></>
                )}

                {props.isAuthenticated ? (
                  <>
                    <ErrorBoundary>
                      {width < 426 ? (
                        <HeaderMobileNew
                          isAuthenticated={props?.isAuthenticated}
                          isAdmin={props.isAdmin}
                          currentLocale={props.currentLocale}
                          onLocaleChange={props.setLocale}
                          ribbonEnv={props.ribbonEnv}
                          isInProduction={props.isInProduction}
                          isSwaggerEnabled={props.isSwaggerEnabled}
                          openMenu={changeOpen}
                        />
                      ) : (
                        <Header
                          isAuthenticated={props?.isAuthenticated}
                          isAdmin={props.isAdmin}
                          currentLocale={props.currentLocale}
                          onLocaleChange={props.setLocale}
                          ribbonEnv={props.ribbonEnv}
                          isInProduction={props.isInProduction}
                          isSwaggerEnabled={props.isSwaggerEnabled}
                        />
                      )}
                    </ErrorBoundary>
                    <div
                      className="main-content"
                      style={{ background: '#fff' }}
                    >
                      <ErrorBoundary>
                        <AppRoutes />
                      </ErrorBoundary>

                      {/* <Footer /> */}
                    </div>
                    <nav className="navbar navbar-dark bg-white navbar-expand d-md-none d-lg-none d-xl-none fixed-bottom">
                      <ul className="navbar-nav nav-justified w-100">
                        <li className="nav-item">
                          <a href="/" className="nav-link">
                            <HomeNav
                              color={path == '/' ? '#F37F26' : '#404040'}
                            />
                            <div
                              className="mt-1"
                              style={
                                path == '/'
                                  ? { color: '#F37F26' }
                                  : { color: '#404040' }
                              }
                            >
                              Home
                            </div>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="/history" className="nav-link">
                            <HistoryNav
                              color={path == '/history' ? '#F37F26' : '#404040'}
                            />
                            <div
                              className="mt-1"
                              style={
                                path == '/history'
                                  ? { color: '#F37F26' }
                                  : { color: '#404040' }
                              }
                            >
                              History
                            </div>
                          </a>
                        </li>
                        <li
                          className="nav-item"
                          style={{ textAlign: 'center' }}
                        >
                          <a
                            href="/draft/create/new"
                            className="nav-link"
                            style={
                              width < 426
                                ? {
                                    left: '42%',
                                    background: '#F37F26',
                                    height: '55px',
                                    width: '55px',
                                    position: 'absolute',
                                    top: -20,
                                    borderRadius: '50%',
                                  }
                                : {
                                    left: '41.4%',
                                    background: '#F37F26',
                                    height: '55px',
                                    width: '55px',
                                    position: 'absolute',
                                    top: -20,
                                    borderRadius: '50%',
                                  }
                            }
                          >
                            <div>
                              <PlusNav
                                color="#FFF"
                                className=""
                                style={{ marginTop: '30%' }}
                              />
                            </div>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="/notification" className="nav-link">
                            <NotifNav
                              color={
                                path == '/notification' ? '#F37F26' : '#404040'
                              }
                            />
                            <div
                              className="mt-1"
                              style={
                                path == '/notification'
                                  ? { color: '#F37F26' }
                                  : { color: '#404040' }
                              }
                            >
                              Notification
                            </div>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="/profile/me" className="nav-link">
                            <ProfileNav
                              color={
                                path == '/profile/me' ? '#F37F26' : '#404040'
                              }
                            />
                            <div
                              className="mt-1"
                              style={
                                path == '/profile/me'
                                  ? { color: '#F37F26' }
                                  : { color: '#404040' }
                              }
                            >
                              Profile
                            </div>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </>
                ) : (
                  <></>
                )}

                {!props.isAuthenticated ? (
                  <>
                    <div
                      style={
                        width < 426
                          ? { paddingBottom: '10%' }
                          : { paddingBottom: '10%' }
                      }
                    >
                      <Switch>
                        <ErrorBoundaryRoute path="/" component={loginx} exact />
                        <ErrorBoundaryRoute
                          path="/document/verified/:id"
                          exact
                          component={documentverified}
                        />
                        <ErrorBoundaryRoute component={NotFound} />
                      </Switch>
                    </div>

                    {/* <Footer /> */}
                  </>
                ) : (
                  <></>
                )}
              </>
            </div>
          </div>
        </Router>
      </div>
    );
  }
};

const mapStateToProps = ({
  authentication,
  applicationProfile,
  locale,
}: IRootState) => ({
  currentLocale: locale.currentLocale,
  isAuthenticated: authentication.isAuthenticated,
  loading: authentication.loading,
  isAdmin: hasAnyAuthority(authentication.account.authorities, [
    AUTHORITIES.ADMIN,
    AUTHORITIES.CS,
    AUTHORITIES.OE,
    AUTHORITIES.KYC,
    AUTHORITIES.ADMIN_OOA3,
    AUTHORITIES.IT_SUPPORT,
  ]),
  ribbonEnv: applicationProfile.ribbonEnv,
  isInProduction: applicationProfile.inProduction,
  isSwaggerEnabled: applicationProfile.isSwaggerEnabled,
});

const mapDispatchToProps = { setLocale, getSession, getProfile };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(App));
