import '../../../../../../scss/bootstrap.min.scss';
import '../../../../../../scss/app.min.scss';
import '../announcement/announcement.scss';

// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import Pagination from '@mui/material/Pagination';
const list = [
  { value: 2 },
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
];

import 'react-datepicker/dist/react-datepicker.css';

import React, { useEffect, useState, Fragment, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from 'reactstrap';

import { IRootState } from 'app/shared/reducers';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { Accordion, Carousel, Form } from 'react-bootstrap';
import CustomReactPanZoom from 'app/component/custom-react-pan-zoom';
import formatDate from 'app/utils/utils';
import { BASE_URL } from 'app/utils/constant';

export interface IHeaderProps {
  isAuthenticated?: boolean;
  isAdmin?: boolean;
  ribbonEnv?: string;
  isInProduction?: boolean;
  isSwaggerEnabled?: boolean;
  currentLocale?: string;
  onLocaleChange?: Function;
  account?: any;
  logout?: any;
  location?: any;
  match?: any;
}

const initialRejectReason = {
  nama: '',
  namaKantor: '',
  tempatTanggalLahir: '',
  fotoKtp: '',
  kotaKtp: '',
  fotoSelfie: '',
  provinsiKtp: '',
  tandaTangan: '',
  others: '',
  fotoNpwp: '',
};

export const UpgradeDetail = (props: IHeaderProps) => {
  let history = useHistory();
  const [search, setSearch] = useState('');
  const [listSearch, setListSearch] = useState([]);
  const [loadingList, setLoadingList] = useState(false);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [size, setSize] = useState<any>(10);
  const [sortType, setSortType] = useState('typeName.keyword,asc');
  const [sortPriority, setSortPriority] = useState('priority.keyword,asc');
  const [sortLastModified, setSortLastModified] = useState(
    'lastApprovedByName.keyword,asc'
  );
  const [sortDate, setSortDate] = useState('date,asc');
  const [limit, setLimit] = useState<any>(5);
  const [listAnnouncement, setListAnnouncement] = useState<any>([]);
  const [showModal, setShowModal] = useState<any>(false);
  const [selectedItem, setItemSelected] = useState<any>(null);
  const [loadingDelete, setLoadingDelete] = useState<any>(false);
  const [date, setDate] = useState<any>('');
  const [listTo, setListTo] = useState<any>([]);
  const [postDate, setPostDate] = useState<any>('');
  const [to, setTo] = useState<any>([]);
  const [fileContent, setFileContent] = useState<any>('');
  const editorRef = useRef(null);
  const [files, setFiles] = React.useState(null);
  const [urlImage, setUrlImage] = useState<any>(null);
  const [title, setTitle] = useState<any>('');
  const [loadingCreate, setLoadingCreate] = useState<boolean>(true);
  const [datePost, setDatePost] = useState<any>('');
  const [dateCreated, setDateCreated] = useState<any>('');
  const [id, setId] = useState<any>('0');
  const [pengirim, setPengirim] = useState<any>('');
  const [urlProfileImage, setUrlProfileImage] = useState<any>('');
  const [index, setIndex] = useState(0);
  const [showModalApprove, setShowModalApprove] = useState<any>(false);
  const [showModalReject, setShowModalReject] = useState<any>(false);
  const [showModalCompare, setShowModalCompare] = useState<any>(false);
  const [showModalPreviewImage, setShowModalPreviewImage] = useState<any>(
    false
  );
  const [showModalApprovalHistory, setshowModalApprovalHistory] = useState<any>(
    false
  );
  const [showImagePreviewId, setShowImagePreviewId] = useState<any>(0);
  const [detail, setDetail] = useState<any>([]);
  const [listApprovalSelected, setListApprovalSelected] = useState<any>([]);
  const [listApproval, setListApproval] = useState<any>([]);
  const [urlImageKTP, setUrlImageKTP] = useState<any>(null);
  const [urlImageNPWP, setUrlImageNPWP] = useState<any>(null);
  const [urlImageSelfie, setUrlImageSelfie] = useState<any>(null);
  const [urlImageTTD, setUrlImageTTD] = useState<any>(null);

  const [sort, setSort] = useState<any>('createdDate');
  const [ascending, setAscending] = useState<boolean>(false);

  const [message, setMessage] = useState('');
  const [othersReason, setOthersReason] = useState<boolean>(false);

  const [dataUser, setDataUser] = useState<any>(null);

  const ws = new WebSocket(
    `${BASE_URL.WSS_HOTSREGIS}ws/approval/all-history-by-customerId`
  );

  // var arrayRejectReason = [];

  var arrayRejectOther = [];

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const [finalReason, setFinalReason] = useState([]);

  const [arrayRejectReason, setArrayRejectReason] = useState([]);

  const [onSubmit, setOnSubmit] = useState<boolean>(false);

  const arrayPayload = [];

  const onDropHandler = (ev) => {
    ev.preventDefault();

    console.log(ev);
    let file = null;
    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      file = [...ev.dataTransfer.items]
        .find((item: any) => item.kind === 'file')
        .getAsFile();
    } else {
      // Use DataTransfer interface to access the file(s)
      file = ev.dataTransfer.files[0];
    }

    console.log(file);
    setFiles(file);
    setUrlImage(URL.createObjectURL(file));
  };

  const onDragOver = (ev) => ev.preventDefault();

  //Non Web socket
  // useEffect(() => {
  //   getApprovalHistory(props.match.params.id);
  // }, [sort, ascending]);

  useEffect(() => {
    console.log('ARRAY VALUE', JSON.stringify(arrayRejectReason));
    // setFinalReason([...finalReason, arrayRejectReason]);
    // arrayPayload.push(arrayRejectReason);
    console.log('ARRAY PAYLOAD UFF', JSON.stringify(arrayPayload));
    if (onSubmit) {
      rejectReviewKYC(detail);
      setOnSubmit(false);
    }
  }, [onSubmit]);

  useEffect(() => {
    var data = JSON.stringify({
      id: props.match.params.id,
      page: page,
      size: size,
      sortBy: sort,
      sortValue: ascending ? 'asc' : 'desc',
    });

    ws.onopen = (e) => {
      console.log('ERROR Message:', e);
      console.log('DATA:', data);
      ws.send(data);
    };
    ws.onmessage = function (e) {
      try {
        console.log('Message:', e);
        setLoadingList(false);
        var res = JSON.parse(e.data);
        setListApproval(res.data?.content);
        setTotalPage(res.data?.totalPages);
        setLoadingList(false);
      } catch (err) {
        console.log(err);
      }
    };
    //clean up function
    return () => {
      if (ws.readyState === 1) {
        // <-- This is important
        ws.close();
      }
    };
  }, [sort, ascending, page, size]);

  function getDataUser() {
    return axios
      .get(`services/uaadocservice/api/account/get-detail`)
      .then((res) => {
        console.log(res.data);
        setDataUser(res.data);
      })
      .catch((err) => {
        setDataUser(null);
      });
  }

  function getDetail(id: string) {
    let data = [];
    let languageValue = 'ID';
    setLoadingList(true);
    axios
      .get(
        `${BASE_URL.HOTSREGIS}api/backOffice/customer-verification/${id}?language=${languageValue}`
      )
      .then(async (res) => {
        console.log('detail nasabah', res.data.data);

        setDetail(res.data.data);

        if (res.data.data.ktpImage != '-') {
          setUrlImageKTP(
            'data:image/png;base64,' + (await getImage(res.data.data.ktpFile))
          );
        }
        if (res.data.data.npwpImage != '-') {
          setUrlImageNPWP(
            'data:image/png;base64,' + (await getImage(res.data.data.npwpFile))
          );
        }
        if (res.data.data.selfieImage != '-') {
          setUrlImageSelfie(
            'data:image/png;base64,' +
              (await getImage(res.data.data.selfieFile))
          );
        }
        if (res.data.data.ttdImage != '-') {
          setUrlImageTTD(
            'data:image/png;base64,' +
              (await getImage(res.data.data.signatureUrl))
          );
        }

        console.log(data);
        setLoadingList(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingList(false);
      });
  }

  async function getAllImage(data: any) {
    var imageValue = '-';
    console.log('data', data);
    console.log('ktp file', data.ktpFile);
    console.log('npwp file', data.npwpFile);
    console.log('selfie file', data.selfieFile);
    console.log('TTD file', data.TTDFile);
    if (data?.ktpFile != null) {
      imageValue = getImage(data?.ktpFile) + '';
      console.log('value image Ktp', imageValue);
      setUrlImageKTP('data:image/png;base64,' + imageValue);
    }
    if (data?.npwpFile != null) {
      imageValue = getImage(data?.npwpFile) + '';
      console.log('value npwp :', imageValue);
      setUrlImageNPWP('data:image/png;base64,' + imageValue);
    }
    if (data?.selfieFile != null) {
      imageValue = getImage(data?.selfieFile) + '';
      console.log('value selfie', urlImageSelfie);
      setUrlImageSelfie('data:image/png;base64,' + imageValue);
    }
    if (data?.signatureUrl != null) {
      imageValue = getImage(data?.signatureUrl) + '';
      console.log('value Signature URL', data?.signatureUrl);
      setUrlImageTTD('data:image/png;base64,' + imageValue);
    }
  }

  const getApprovalHistory = (id) => {
    // axios
    //   .get(
    //     `${BASE_URL.HOTSREGIS}api/approval/all-history-by-customer?id=${id}&sortBy=${sort}&sortValue=${
    //       ascending ? 'asc' : 'desc'
    //     }`
    //   )
    //   .then((res) => {
    //     console.log('approval history', res);
    //     setListApproval(res.data.data?.content);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    // connectWs(id);
  };

  const approveKYC = (data) => {
    setLoadingCreate(true);
    const json = JSON.stringify({
      id: data?.userId,
      createdBy: dataUser?.firstName,
    });

    // axios.post(`http://172.20.10.2:7667/api/approval/approve-upgrade-ekyc`, json,{
    axios
      .post(`${BASE_URL.HOTSREGIS}api/approval/approve-upgrade-ekyc`, json, {
        headers: {
          // Overwrite Axios's automatically set Content-Type
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        if (res.data.code == 'SUCCESS') {
          toast.success('Success Approved', {
            position: 'top-left',
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setLoadingCreate(false);

          setShowModalApprove(false);
          setItemSelected(null);
          history.replace(`/upgrade/list`);
        } else {
          toast.error('Failed Approved: ' + res.data?.message, {
            position: 'top-left',
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setShowModalApprove(false);
          setItemSelected(null);
          setLoadingCreate(false);
        }
      })
      .catch((err) => {
        setShowModalApprove(false);
        setItemSelected(null);
        setLoadingCreate(false);
        toast.error('Failed Approved: ' + err, {
          position: 'top-left',
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        console.log('ERROR Approved', err);
      });
  };

  // const rejectHard = (data) => {
  //   setLoadingCreate(true);

  //   const formData = new FormData();
  //   let payloaddata = {
  //     id: data?.userId,
  //     createdBy: dataUser?.firstName,
  //   };

  //   const json = JSON.stringify(payloaddata);
  //   axios
  //     .post(`${BASE_URL.HOTSREGIS}api/approval/reject-ekyc`, json, {
  //       headers: {
  //         // Overwrite Axios's automatically set Content-Type
  //         'Content-Type': 'application/json',
  //       },
  //     })
  //     .then((res) => {
  //       if (res.data.code == 'SUCCESS') {
  //         setLoadingCreate(false);
  //         setShowModalReject(false);
  //         setItemSelected(null);
  //         history.replace(`/upgrade/list`);
  //         toast.success('Success Reject', {
  //           position: 'top-left',
  //           autoClose: 8000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //         });
  //       } else {
  //         setLoadingCreate(false);
  //         setShowModalReject(false);
  //         setItemSelected(null);
  //         history.replace(`/upgrade/list`);
  //         toast.error('Failed Reject: ' + res.data.message, {
  //           position: 'top-left',
  //           autoClose: 8000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       setShowModalReject(false);
  //       setItemSelected(null);
  //       setLoadingCreate(false);
  //       toast.error('Failed Reject: ' + err, {
  //         position: 'top-left',
  //         autoClose: 8000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });

  //       console.log('ERROR Reject', err);
  //     });
  // };

  const rejectReviewKYC = (data) => {
    setLoadingCreate(true);
    // var arrayTemp = [...arrayRejectReason, ...arrayRejectOther];
    console.log('finalReason', JSON.stringify(finalReason));
    let payloaddata = {
      id: data?.userId,
      createdBy: dataUser?.firstName,
      listReject: arrayRejectReason,
    };
    console.log('PAYLOAD', JSON.stringify(payloaddata));

    const json = JSON.stringify(payloaddata);
    axios
      .post(
        `${BASE_URL.HOTSREGIS}api/approval/reject-review-upgrade-ekyc`,
        json,
        {
          headers: {
            // Overwrite Axios's automatically set Content-Type
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        if (res.data.code == 'SUCCESS') {
          toast.success('Success Reject', {
            position: 'top-left',
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setLoadingCreate(false);
          setShowModalReject(false);
          setItemSelected(null);
          history.replace(`/upgrade/list`);
        } else {
          toast.error('Failed Reject: ' + res.data?.message, {
            position: 'top-left',
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setLoadingCreate(false);
          setShowModalReject(false);
          setItemSelected(null);
          history.replace(`/upgrade/list`);
        }
      })
      .catch((err) => {
        toast.error('Failed Reject: ' + err, {
          position: 'top-left',
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setShowModalReject(false);
        setItemSelected(null);
        setLoadingCreate(false);
        console.log('ERROR', err);
      });
  };

  const MarkAsREad = (data) => {
    axios
      .post(
        `services/documentservice/api/notification-user/mark-has-read?notificationId=${data.notificationId}`
      )
      .then((res) => {})
      .catch((err) => {});
  };

  useEffect(() => {
    console.log('data search', props.location.state?.search);
    console.log(props.match.params.id);
    // alert(props.location.state.search)
    // getSearch(props.location.state?.search);
    getDetail(props.match.params.id);
    // getApprovalHistory(props.match.params.id);
    getTitle(props.match.params.title);
    getDataUser();
  }, []);

  const getTitle = (title) => {
    switch (title) {
      case '1':
        console.log('WAITING TAB');
        setTitle('Waiting');
        break;
      case '2':
        console.log('APPROVED TAB');
        setTitle('Approved');
        break;
      case '3':
        console.log('REJECTED TAB');
        setTitle('Rejected');
        break;
      default:
        console.log('DEFAULT VALUE TAB');
        setTitle('Default');
        break;
    }
  };

  const delayTime = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const handleClose = () => {
    setShowModalApprove(false);
    setShowModalCompare(false);
    setShowModalReject(false);
    setShowModalPreviewImage(false);
    setshowModalApprovalHistory(false);
  };

  function changeOccupationLayout(occupationCode: String) {
    var layoutOccupation;

    switch (occupationCode) {
      case '05,4':
        //HOUSEWIFE
        layoutOccupation = (
          <>
            <div className="row align-items-center mb-3">
              <div className="col-3">Pekerjaan</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded   w-100"
                  type="text"
                  disabled={true}
                  value={detail?.occupationName}
                />
              </div>
              <div className="col-3">Tanggal Lahir Pasangan</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded   w-100"
                  type="text"
                  disabled={true}
                  value={formatDate(detail?.dateOfBirthSpouse, 'DD MMM YYYY')}
                />
              </div>
            </div>

            <div className="row align-items-center mb-3">
              <div className="col-3">Nomor Identitas Pasangan</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded   w-100"
                  type="text"
                  disabled={true}
                  value={detail?.spouseIdNumber}
                />
              </div>
              <div className="col-3">No Telpon Pasangan</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded   w-100"
                  type="text"
                  disabled={true}
                  value={detail?.spousePhoneNo}
                />
              </div>
            </div>
            <div className="row align-items-center mb-3">
              <div className="col-3">Jumlah Tanggungan</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded   w-100"
                  type="text"
                  disabled={true}
                  value={detail?.numberOfDependents}
                />
              </div>
              <div className="col-3">Nama Perusahaan Pasangan</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded   w-100"
                  type="text"
                  disabled={true}
                  value={detail?.spouseCompanyName}
                />
              </div>
            </div>
          </>
        );
        break;
      case '01,6':
        //STUDENT
        layoutOccupation = (
          <>
            <div className="row align-items-center mb-3">
              <div className="col-3">Pekerjaan</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded   w-100"
                  type="text"
                  disabled={true}
                  value={detail?.occupationName}
                />
              </div>
              <div className="col-3">Nama Orang Tua</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded   w-100"
                  type="text"
                  disabled={true}
                  value={detail?.parentName}
                />
              </div>
            </div>

            <div className="row align-items-center mb-3">
              <div className="col-3">Nomor Identitas Pemberi Manfaat</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded   w-100"
                  type="text"
                  disabled={true}
                  value={detail?.parentIdNumber}
                />
              </div>
              <div className="col-3">Tanggal Lahir Orang Tua</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded   w-100"
                  type="text"
                  disabled={true}
                  value={formatDate(detail?.dateOfBirthParent, 'DD MMM YYYY')}
                />
              </div>
            </div>
          </>
        );
        break;
      default:
        layoutOccupation = (
          <>
            <div className="row align-items-center mb-3">
              <div className="col-3">Pekerjaan</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded   w-100"
                  type="text"
                  disabled={true}
                  value={detail?.occupationName}
                />
              </div>
              <div className="col-3">Nama Perusahaan / Tempat Bekerja</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded   w-100"
                  type="text"
                  disabled={true}
                  value={detail?.companyName}
                />
              </div>
            </div>
            <div className="row align-items-center mb-3">
              <div className="col-3">Bidang Usaha</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded   w-100"
                  type="text"
                  disabled={true}
                  value={detail?.companyTypeName}
                />
              </div>
              <div className="col-3">Jabatan</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded   w-100"
                  type="text"
                  disabled={true}
                  value={detail?.positionName}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <h5>Alamat Perusahaan Tempat Bekerja</h5>
              </div>
            </div>
            <div className="row align-items-center mb-3">
              <div className="col-3">Alamat</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded w-100"
                  type="text"
                  disabled={true}
                  value={detail?.companyAddress}
                />
              </div>
              <div className="col-3">Negara</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded w-100"
                  type="text"
                  disabled={true}
                  value={detail?.companyCountry}
                />
              </div>
            </div>

            <div className="row align-items-center mb-3">
              <div className="col-3">Provinsi</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded w-100"
                  type="text"
                  disabled={true}
                  value={detail?.companyProvince}
                />
              </div>
              <div className="col-3">Kab/Kota</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded w-100"
                  type="text"
                  disabled={true}
                  value={detail?.companyCity}
                />
                {/* <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'GAYO LUES'}/> */}
              </div>
            </div>
            <div className="row align-items-center mb-3">
              <div className="col-3">Kelurahan</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded w-100"
                  type="text"
                  disabled={true}
                  value={detail?.companyVillage}
                />
              </div>
              <div className="col-3">Kecamatan</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded w-100"
                  type="text"
                  disabled={true}
                  value={detail?.companyDistrict}
                />
              </div>
            </div>
            <div className="row align-items-center mb-3">
              <div className="col-3">RT/RW</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded w-100"
                  type="text"
                  disabled={true}
                  value={detail?.companyDetailRtRw}
                />
                {/* <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'GAYO LUES'}/> */}
              </div>
              <div className="col-3">Kode Pos</div>
              <div className="col-3">
                <input
                  className="py-2 px-2 rounded w-100"
                  type="text"
                  disabled={true}
                  value={detail?.companyPostalCode}
                />
              </div>
            </div>
          </>
        );
        break;
    }
    return layoutOccupation;
  }

  function handleRejectList(item: any) {
    var rejectReason = '';

    if (item?.listReject != null) {
      rejectReason = item?.listReject[0]?.rejectReasonDetail;
    } else {
      //Masuk ke else
    }

    return rejectReason;
  }

  function removeRejectReason(reason: String) {
    let tempReason = [];
    if (arrayRejectReason != null) {
      tempReason = arrayRejectReason;
    }

    var filteredArray = tempReason.filter(
      (p: { rejectReasonName: string }) => p.rejectReasonName !== reason
    );

    // arrayRejectReason = filteredArray;
    setArrayRejectReason(filteredArray);
    console.log('FILTERED ARRAY', JSON.stringify(filteredArray));
    console.log('AFTER DELETE ARRAY', JSON.stringify(arrayRejectReason));
  }

  function getImage(imageId: string) {
    return axios
      .get(`${BASE_URL.HOTSREGIS}api/backOffice/load-image/${imageId}`)
      .then((response) => {
        // if (response.data == 'SUCCESS') {
        console.log('Data image', JSON.stringify(response.data.data));
        console.log('Data image', response.data.data);
        return response.data.data;
        // }
      })
      .catch((error) => {
        console.log('Error Image', error);
        // setLoadingList(false);
      });
  }

  const handleMessageChange = (event) => {
    // 👇️ access textarea value
    setMessage(event.target.value);
    console.log(event.target.value);
  };

  function handleButton() {
    // console.log('title', title);
    switch (title) {
      case 'Waiting':
        return (
          <>
            <a
              className="btn btn-primary text-white text-end mr-2"
              onClick={(data) => setShowModalCompare(true)}
              style={{ background: '#F37F26' }}
            >
              Compare Data KTP
            </a>
            <a
              className="btn btn-danger text-white text-end mr-2"
              onClick={(data) => setShowModalReject(true)}
              style={{ background: '#CB3A31' }}
            >
              Reject
            </a>
            <a
              className="btn btn-success text-white text-end mr-4"
              onClick={(data) => setShowModalApprove(true)}
              style={{ background: '#43936C' }}
            >
              Approve
            </a>
          </>
        );
        break;
      default:
        return (
          <>
            <a
              className="btn btn-primary text-white text-end mr-2"
              onClick={(data) => setShowModalCompare(true)}
              style={{ background: '#F37F26' }}
            >
              Compare Data KTP
            </a>
          </>
        );
        break;
    }
  }

  const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    console.log(value);
    setPage(value);
  };

  const changeShow = (e) => {
    let show = e.target.value;
    console.log(limit);
    setPage(1);
    setSize(show);
  };

  return (
    // !loadingList ? (
    <div className="px-5 pt-4" style={{ background: '#fff', height: '100%' }}>
      <button className="btn px-0" onClick={() => history.goBack()}>
        <FontAwesomeIcon size="2x" icon="arrow-left" color="#0A0A0A" />{' '}
      </button>
      <div className="row mt-4">
        <div className="col-6">
          <h1 className="mb-0 font-size-32">Upgrade Account - {title}</h1>
        </div>
        <div className="col-6 text-end">{handleButton()}</div>
        <div className="row mt-3">
          <p className="" style={{ fontSize: '18px' }}>
            {' '}
            {detail?.name}
          </p>
        </div>
        <div className="row mt-3">
          <div className="col-12">
            <Accordion
              defaultActiveKey={
                detail?.biometricScore < 70 ? ['1', '7', '5'] : ['1', '7', '']
              }
              alwaysOpen
            >
              <Accordion.Item eventKey="1">
                <Accordion.Header>Dokumen</Accordion.Header>
                <Accordion.Body style={{ background: '#fff' }}>
                  <div className="row align-items-center mb-3">
                    <div className="col-6">KTP</div>
                    <div className="col-6">NPWP</div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-6">
                      <img
                        className="image-button w-50"
                        src={urlImageKTP}
                        alt=""
                        onClick={(data) => {
                          setShowModalPreviewImage(true);
                          handleSelect(0, null);
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <img
                        className="image-button w-50"
                        src={urlImageNPWP}
                        alt=""
                        onClick={(data) => {
                          setShowModalPreviewImage(true);
                          handleSelect(1, null);
                        }}
                      />
                    </div>
                  </div>

                  <div className="row align-items-center mb-3">
                    <div className="col-6">Selfie</div>
                    <div className="col-6">Tanda Tangan</div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-6">
                      {/* <img className='image-button w-50' src="content/images/selfie-1.jpeg" alt="" onClick={(data) => { */}
                      <img
                        className="image-button w-50"
                        src={urlImageSelfie}
                        alt=""
                        onClick={(data) => {
                          setShowModalPreviewImage(true);
                          handleSelect(2, null);
                        }}
                      />
                    </div>
                    <div className="col-6">
                      {/* <img className='image-button w-50' src="content/images/ttd-1.jpeg" alt="" onClick={(data) => { */}
                      <img
                        className="image-button w-50"
                        src={urlImageTTD}
                        alt=""
                        onClick={(data) => {
                          setShowModalPreviewImage(true);
                          handleSelect(3, null);
                        }}
                      />
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header
                  className={
                    detail?.biometricScore < 70
                      ? 'biometric-down'
                      : 'biometric-up'
                  }
                >
                  <h5>Score</h5>{' '}
                  {/* <i
                    className="bx bx-info-circle"
                    style={{ fontSize: '14px' }}
                  ></i> */}
                </Accordion.Header>
                <Accordion.Body className="" style={{ background: '#fff' }}>
                  <div className="row align-items-center mb-3">
                    <div className="col-3">AML Score</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={(Math.round(detail?.score * 100) / 100).toFixed(
                          2
                        )}
                      />
                    </div>
                    <div className="col-3">Risk Status</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.riskStatus}
                      />
                    </div>
                  </div>
                  <div className="row align-items-center mb-3">
                    <div className="col-3">Liveness Score</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={(
                          Math.round(detail?.faceComparisonScore * 100) / 100
                        ).toFixed(2)}
                      />
                    </div>
                    <div className="col-3">Biometric Score</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        style={
                          detail?.biometricScore < 70
                            ? { border: '2px solid #CB3A31' }
                            : {}
                        }
                        value={detail?.biometricScore}
                      />
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  <h5>KSEI</h5>
                </Accordion.Header>
                <Accordion.Body className="" style={{ background: '#fff' }}>
                  <div className="row align-items-center mb-3">
                    <div className="col-3">SID</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.sid}
                      />
                    </div>
                    <div className="col-3">SRE</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.sre}
                      />
                    </div>
                  </div>
                </Accordion.Body>
                <Accordion.Body className="" style={{ background: '#fff' }}>
                  <div className="row align-items-center mb-3">
                    <div className="col-3">Rekening KPEI</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.kpeiNo}
                      />
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <h5>Data Nasabah</h5>
                </Accordion.Header>
                <Accordion.Body style={{ background: '#fff' }}>
                  <div className="row align-items-center  mb-3">
                    <div className="col-3">User ID</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.username}
                      />
                    </div>
                    <div className="col-3">Nomor Identitas</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.idNumber}
                      />
                    </div>
                  </div>
                  <div className="row align-items-center  mb-3">
                    <div className="col-3">Nama Lengkap (Sesuai KTP)</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.name}
                      />
                      {/* <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'TEST19AKKA'}/> */}
                    </div>
                    <div className="col-3">Phone Number</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        // value={(detail?.dialCode + detail?.phoneNumber)
                        value={(detail?.dialCode + detail?.phoneNumber)
                          .toString()
                          .replace(/(?<=\d\d)\d(?=\d{2})/g, '*')}
                      />
                      {/* <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'082111182675'}/> */}
                    </div>
                  </div>
                  <div className="row align-items-center  mb-3">
                    <div className="col-3">Tempat & Tanggal Lahir</div>
                    <div className="col-2">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.placeOfBirth}
                      />
                    </div>
                    <div className="col-1">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={formatDate(detail?.birthday, 'DD MMM YYYY')}
                      />
                    </div>
                    <div className="col-3">NPWP</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.npwpId}
                      />
                      {/* <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'855739629506000'}/> */}
                    </div>
                  </div>
                  <div className="row align-items-center mb-3">
                    <div className="col-3">Email</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.email}
                      />
                    </div>
                    <div className="col-3">Negara tempat lahir</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.countryOfBirth}
                      />
                    </div>
                  </div>
                  <div className="row align-items-center mb-3">
                    <div className="col-3">Agama</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.religionName}
                      />
                    </div>
                    <div className="col-3">Nama Gadis Ibu Kandung</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.motherName}
                      />
                    </div>
                  </div>
                  <div className="row align-items-center mb-3">
                    <div className="col-3">Jenis Kelamin</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.genderName}
                      />
                    </div>
                    <div className="col-3">Status Rumah</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.ownershipOfHouseStatusName}
                      />
                    </div>
                  </div>
                  <div className="row align-items-center mb-3">
                    <div className="col-3">Status Pernikahan</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.maritalStatusName}
                      />
                    </div>
                    {detail?.maritalStatusName?.toUpperCase() === 'MENIKAH' && (
                      <>
                        <div className="col-3">Nama Pasangan</div>
                        <div className="col-3">
                          <input
                            className="py-2 px-2 rounded w-100"
                            type="text"
                            disabled={true}
                            value={detail?.spouseName}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <h5>Alamat Sesuai KTP</h5>
                    </div>
                  </div>
                  <div className="row align-items-center mb-3">
                    <div className="col-3">Alamat</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.address}
                      />
                    </div>
                    <div className="col-3">Negara</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.country}
                      />
                    </div>
                  </div>
                  <div className="row align-items-center mb-3">
                    <div className="col-3">Provinsi</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.province}
                      />
                    </div>
                    <div className="col-3">Kab/Kota</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.city}
                      />
                      {/* <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'GAYO LUES'}/> */}
                    </div>
                  </div>
                  <div className="row align-items-center mb-3">
                    <div className="col-3">Kelurahan</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.village}
                      />
                    </div>
                    <div className="col-3">Kecamatan</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.district}
                      />
                    </div>
                  </div>
                  <div className="row align-items-center mb-3">
                    <div className="col-3">RT/RW</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.detailRtRw}
                      />
                      {/* <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'GAYO LUES'}/> */}
                    </div>
                    <div className="col-3">Kode Pos</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.postalCode}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <h5>Alamat Domisili</h5>
                    </div>
                  </div>
                  <div className="row align-items-center mb-3">
                    <div className="col-3">Alamat</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.domicileAddress}
                      />
                    </div>
                    <div className="col-3">Negara</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.domicileCountry}
                      />
                    </div>
                  </div>
                  <div className="row align-items-center mb-3">
                    <div className="col-3">Provinsi</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.domicileProvince}
                      />
                    </div>
                    <div className="col-3">Kab/Kota</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.domicileCity}
                      />
                      {/* <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'GAYO LUES'}/> */}
                    </div>
                  </div>
                  <div className="row align-items-center mb-3">
                    <div className="col-3">Kelurahan</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.domicileVillage}
                      />
                    </div>
                    <div className="col-3">Kecamatan</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.domicileDistrict}
                      />
                    </div>
                  </div>
                  <div className="row align-items-center mb-3">
                    <div className="col-3">RT/RW</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.domicileDetailRtRw}
                      />
                      {/* <input className='py-2 px-2 rounded w-100' type="text" disabled={true} value={'GAYO LUES'}/> */}
                    </div>
                    <div className="col-3">Kode Pos</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.domicilePostalCode}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <h5>Alamat Surat Menyurat</h5>
                    </div>
                  </div>
                  <div className="row align-items-center mb-3">
                    <div className="col-3">Alamat Surat Menyurat</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.mailingAddressName}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <h5>Data SLIK</h5>
                    </div>
                  </div>

                  <div className="row align-items-center mb-3">
                    <div className="col-3">Nomor Identitas Pasangan</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.spouseIdNumber ?? '-'}
                      />
                    </div>
                    <div className="col-3">Tanggal Lahir Pasangan</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={
                          detail?.dateOfBirthSpouse
                            ? formatDate(
                                detail?.dateOfBirthSpouse,
                                'DD MMM YYYY'
                              )
                            : '-'
                        }
                      />
                    </div>
                  </div>
                  <div className="row align-items-center mb-3">
                    <div className="col-3">Nama Pasangan Suami/Istri</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.spouseName ?? '-'}
                      />
                    </div>
                    <div className="col-3">No. HP Pasangan</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.spousePhoneNo ?? '-'}
                      />
                    </div>
                  </div>
                  <div className="row align-items-center mb-3">
                    <div className="col-3">Nama Perusahaan Pasangan</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.spouseCompanyName ?? '-'}
                      />
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <h5>Data Bank</h5>
                </Accordion.Header>
                <Accordion.Body className="" style={{ background: '#fff' }}>
                  <div className="row align-items-center mb-3">
                    <div className="col-3">Bank RDN</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.bankRDNName}
                      />
                    </div>
                    <div className="col-3">No Rekening RDN</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.rdnAccount}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <h5>Rekening Pencairan</h5>
                    </div>
                  </div>

                  <div className="row align-items-center mb-3">
                    <div className="col-3">Bank</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded   w-100"
                        type="text"
                        disabled={true}
                        value={detail?.bankDestinationName}
                      />
                    </div>
                    <div className="col-3">No Rekening</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded   w-100"
                        type="text"
                        disabled={true}
                        value={detail?.accountBankNo}
                      />
                    </div>
                  </div>

                  <div className="row align-items-center mb-3">
                    <div className="col-3">Nama Pemilik Rekening</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded   w-100"
                        type="text"
                        disabled={true}
                        value={detail?.accountBankName}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <h5>Lain - lain</h5>
                    </div>
                  </div>

                  <div className="row align-items-center mb-3">
                    <div className="col-3">Nomor Rekening Sekarang</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded   w-100"
                        type="text"
                        disabled={true}
                        value={detail?.accountBankNoExisting}
                      />
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  <h5>Data Pekerjaan</h5>
                </Accordion.Header>
                <Accordion.Body style={{ background: '#fff' }}>
                  {/* Checking Pekerjaan */}
                  {changeOccupationLayout(detail?.occupation)}
                  {/* Close Checking Pekerjaan */}
                  <div className="row">
                    <div className="col-12">
                      <h5>Pendapatan</h5>
                    </div>
                  </div>
                  <div className="row align-items-center mb-3">
                    <div className="col-3">Pendapatan Kotor Per Tahun</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.salaryRangeName}
                      />
                    </div>
                    <div className="col-3">Jumlah Tanggungan</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded   w-100"
                        type="text"
                        disabled={true}
                        value={detail?.numberOfDependents}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <h5>Sumber Dana & Tujuan Investasi</h5>
                    </div>
                  </div>
                  <div className="row align-items-center mb-3">
                    <div className="col-3">Sumber Dana</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.sourceOfIncomeName}
                      />
                    </div>
                    <div className="col-3">Tujuan Investasi</div>
                    <div className="col-3">
                      <input
                        className="py-2 px-2 rounded w-100"
                        type="text"
                        disabled={true}
                        value={detail?.investmentGoalName}
                      />
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              {detail?.accountTypeName == 'PREMIUM' ? (
                <>
                  <Accordion.Item eventKey="8">
                    <Accordion.Header>
                      <h5>Data Beneficial Owner</h5>
                    </Accordion.Header>
                    <Accordion.Body style={{ background: '#fff' }}>
                      <div className="row align-items-center mb-3">
                        <div className="col-3">
                          Jenis Identitas Pemilik Manfaat
                        </div>
                        <div className="col-3">
                          <input
                            className="py-2 px-2 rounded w-100"
                            type="text"
                            disabled={true}
                            value={detail?.boIdTypeName}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <h5>Sumber Dana & Tujuan Investasi</h5>
                        </div>
                      </div>
                      <div className="row align-items-center mb-3">
                        <div className="col-3">
                          Nomor Identitas Pemberi Manfaat
                        </div>
                        <div className="col-3">
                          <input
                            className="py-2 px-2 rounded w-100"
                            type="text"
                            disabled={true}
                            value={detail?.boIdNumber}
                          />
                        </div>
                      </div>
                      <div className="row align-items-center mb-3">
                        <div className="col-3">Nama Pemilik Manfaat</div>
                        <div className="col-3">
                          <input
                            className="py-2 px-2 rounded w-100"
                            type="text"
                            disabled={true}
                            value={detail?.boName}
                          />
                        </div>
                        <div className="col-3">Hubungan Dengan Nasabah</div>
                        <div className="col-3">
                          <input
                            className="py-2 px-2 rounded w-100"
                            type="text"
                            disabled={true}
                            value={detail?.boRelationName}
                          />
                        </div>
                      </div>
                      <div className="row align-items-center  mb-3">
                        <div className="col-3">
                          Tempat & Tgl Lahir Pemilik Manfaat
                        </div>
                        <div className="col-2">
                          <input
                            className="py-2 px-2 rounded w-100"
                            type="text"
                            disabled={true}
                            value={detail?.boPlaceOfBirth}
                          />
                        </div>
                        <div className="col-1">
                          <input
                            className="py-2 px-2 rounded w-100"
                            type="text"
                            disabled={true}
                            value={formatDate(
                              detail?.boBirthday,
                              'DD MMM YYYY'
                            )}
                          />
                        </div>
                      </div>
                      <div className="row align-items-center mb-3">
                        <div className="col-3">No Telepon Pemilik Manfaat</div>
                        <div className="col-3">
                          <input
                            className="py-2 px-2 rounded w-100"
                            type="text"
                            disabled={true}
                            value={detail?.boPhoneNo}
                          />
                        </div>
                        <div className="col-3">
                          Bidang Usaha Pemilik Manfaat
                        </div>
                        <div className="col-3">
                          <input
                            className="py-2 px-2 rounded w-100"
                            type="text"
                            disabled={true}
                            value={detail?.boCompanyTypeName}
                          />
                        </div>
                      </div>
                      <div className="row align-items-center  mb-3">
                        <div className="col-3">Lama Bekerja</div>
                        <div className="col-2">
                          <input
                            className="py-2 px-2 rounded w-100"
                            type="text"
                            disabled={true}
                            value={detail?.boWorkYear}
                          />
                        </div>
                        <div className="col-1">
                          <input
                            className="py-2 px-2 rounded w-100"
                            type="text"
                            disabled={true}
                            value={detail?.boWorkMonth}
                          />
                        </div>
                        <div className="col-3">
                          Nama Tempat Bekerja Pemilik Manfaat
                        </div>
                        <div className="col-3">
                          <input
                            className="py-2 px-2 rounded w-100"
                            type="text"
                            disabled={true}
                            value={detail?.boCompanyName}
                          />
                        </div>
                      </div>
                      <div className="row align-items-center mb-3">
                        <div className="col-3">Jabatan Pemilik Manfaat</div>
                        <div className="col-3">
                          <input
                            className="py-2 px-2 rounded w-100"
                            type="text"
                            disabled={true}
                            value={detail?.boPositionName}
                          />
                        </div>
                        <div className="col-3">Sumber Dana Pemilik Manfaat</div>
                        <div className="col-3">
                          <input
                            className="py-2 px-2 rounded w-100"
                            type="text"
                            disabled={true}
                            value={detail?.boSourceOfIncomeName}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <h5>Alamat Pemilik Manfaat</h5>
                        </div>
                      </div>
                      <div className="row align-items-center mb-3">
                        <div className="col-3">Alamat</div>
                        <div className="col-3">
                          <input
                            className="py-2 px-2 rounded w-100"
                            type="text"
                            disabled={true}
                            value={detail?.boAddress}
                          />
                        </div>
                        <div className="col-3">Negara</div>
                        <div className="col-3">
                          <input
                            className="py-2 px-2 rounded w-100"
                            type="text"
                            disabled={true}
                            value={detail?.boCountry}
                          />
                        </div>
                      </div>

                      <div className="row align-items-center mb-3">
                        <div className="col-3">Kab/Kota</div>
                        <div className="col-3">
                          <input
                            className="py-2 px-2 rounded w-100"
                            type="text"
                            disabled={true}
                            value={detail?.boCity}
                          />
                        </div>
                        <div className="col-3">Kelurahan</div>
                        <div className="col-3">
                          <input
                            className="py-2 px-2 rounded w-100"
                            type="text"
                            disabled={true}
                            value={detail?.boVillage}
                          />
                        </div>
                      </div>
                      <div className="row align-items-center mb-3">
                        <div className="col-3">Kecamatan</div>
                        <div className="col-3">
                          <input
                            className="py-2 px-2 rounded w-100"
                            type="text"
                            disabled={true}
                            value={detail?.boDistrict}
                          />
                        </div>
                        <div className="col-3">RT/RW</div>
                        <div className="col-3">
                          <input
                            className="py-2 px-2 rounded w-100"
                            type="text"
                            disabled={true}
                            value={detail?.boRtrw}
                          />
                        </div>
                      </div>
                      <div className="row align-items-center mb-3">
                        <div className="col-3">Provinsi</div>
                        <div className="col-3">
                          <input
                            className="py-2 px-2 rounded w-100"
                            type="text"
                            disabled={true}
                            value={detail?.boProvince}
                          />
                        </div>

                        <div className="col-3">Kode Pos</div>
                        <div className="col-3">
                          <input
                            className="py-2 px-2 rounded w-100"
                            type="text"
                            disabled={true}
                            value={detail?.boPostalCode}
                          />
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </>
              ) : (
                <></>
              )}
              <Accordion.Item eventKey="7">
                <Accordion.Header>
                  <h5>Approval History</h5>
                </Accordion.Header>
                <Accordion.Body style={{ background: '#fff' }}>
                  <div className="row align-items-center mb-3">
                    <table className="table table-centered table-nowrap mb-0 table-responsive">
                      <thead
                        className=""
                        style={{
                          background: '#fff',
                          borderTopLeftRadius: 30,
                          borderTopRightRadius: 30,
                        }}
                      >
                        <tr>
                          <th className="py-4 px-4">No</th>
                          <th
                            className="py-4 px-4 w-25"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setSort('createdDate');
                              setAscending(!ascending);
                            }}
                          >
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                              className="gap-4"
                            >
                              <span className="mr-1">Created Date</span>
                              <span className="mt-1">
                                {sort == 'createdDate' && ascending == true && (
                                  <i
                                    className="bx bxs-up-arrow"
                                    style={{ fontSize: '10px' }}
                                  ></i>
                                )}
                                {sort == 'createdDate' &&
                                  ascending == false && (
                                    <i
                                      className="bx bxs-down-arrow  text-right"
                                      style={{ fontSize: '10px' }}
                                    ></i>
                                  )}
                                {sort != 'createdDate' && (
                                  <i
                                    className="bx bxs-sort-alt text-right"
                                    style={{ fontSize: '14px' }}
                                  ></i>
                                )}
                              </span>
                            </div>
                          </th>
                          <th className="px-4 w-25">User ID</th>
                          <th
                            className="py-4 px-4"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setSort('status');
                              setAscending(!ascending);
                            }}
                          >
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                              className="gap-4"
                            >
                              <span className="mr-1">Status</span>
                              <span className="mt-1">
                                {sort == 'status' && ascending == true && (
                                  <i
                                    className="bx bxs-up-arrow"
                                    style={{ fontSize: '10px' }}
                                  ></i>
                                )}
                                {sort == 'status' && ascending == false && (
                                  <i
                                    className="bx bxs-down-arrow  text-right"
                                    style={{ fontSize: '10px' }}
                                  ></i>
                                )}
                                {sort != 'status' && (
                                  <i
                                    className="bx bxs-sort-alt text-right"
                                    style={{ fontSize: '14px' }}
                                  ></i>
                                )}
                              </span>
                            </div>
                          </th>
                          <th
                            className="py-4 px-4 w-25"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setSort('createdBy');
                              setAscending(!ascending);
                            }}
                          >
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                              className="gap-4"
                            >
                              <span className="mr-1">Approval</span>
                              <span className="mt-1">
                                {sort == 'createdBy' && ascending == true && (
                                  <i
                                    className="bx bxs-up-arrow"
                                    style={{ fontSize: '10px' }}
                                  ></i>
                                )}
                                {sort == 'createdBy' && ascending == false && (
                                  <i
                                    className="bx bxs-down-arrow  text-right"
                                    style={{ fontSize: '10px' }}
                                  ></i>
                                )}
                                {sort != 'createdBy' && (
                                  <i
                                    className="bx bxs-sort-alt text-right"
                                    style={{ fontSize: '14px' }}
                                  ></i>
                                )}
                              </span>
                            </div>
                          </th>
                          <th className="px-4 w-25">Revision</th>
                        </tr>
                      </thead>
                      <tbody className="" style={{ background: '#fff' }}>
                        {listApproval.map((item, index) => {
                          return (
                            <tr
                              className=""
                              style={{
                                background: '#FFF',
                              }}
                            >
                              <td className="text-center">{index + 1}</td>
                              <td
                                className=""
                                style={{
                                  wordBreak: 'break-all',
                                  minWidth: '200px',
                                  maxWidth: '250px',
                                  whiteSpace: 'pre-wrap',
                                }}
                              >
                                {formatDate(
                                  item?.createdDate,
                                  'DD/MM/YY HH:mm'
                                )}
                              </td>
                              <td
                                className=""
                                style={{
                                  wordBreak: 'break-all',
                                  minWidth: '300px',
                                  maxWidth: '500px',
                                  whiteSpace: 'pre-wrap',
                                }}
                              >
                                {item?.username}
                              </td>
                              <td
                                className=""
                                style={{
                                  wordBreak: 'break-all',
                                  minWidth: '300px',
                                  maxWidth: '400px',
                                  whiteSpace: 'pre-wrap',
                                }}
                              >
                                {item?.status}
                              </td>
                              <td
                                className=""
                                style={{
                                  wordBreak: 'break-all',
                                  minWidth: '200px',
                                  maxWidth: '300px',
                                  whiteSpace: 'pre-wrap',
                                }}
                              >
                                {item?.createdBy}
                              </td>
                              <td
                                className="align-items-center"
                                style={{
                                  wordBreak: 'break-all',
                                  minWidth: '350px',
                                  maxWidth: '500px',
                                  whiteSpace: 'pre-wrap',
                                }}
                              >
                                {item?.listReject?.length == null ? (
                                  <></>
                                ) : (
                                  <div className="row">
                                    <div
                                      className="col-1"
                                      onClick={(data) => {
                                        setshowModalApprovalHistory(true);
                                        setListApprovalSelected(
                                          item?.listReject
                                        );
                                      }}
                                      style={{ cursor: 'pointer' }}
                                    >
                                      <svg
                                        width="30px"
                                        height="30px"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="mt-2 mr-2"
                                      >
                                        {item?.listReject?.length > 1 ? (
                                          <image href="content/images/approval/ic_many_revision.svg" />
                                        ) : (
                                          <image href="content/images/approval/ic_one_revision.svg" />
                                        )}
                                      </svg>
                                    </div>
                                    <div className="col-11">
                                      {handleRejectList(item)}
                                    </div>
                                  </div>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-6">
                      <div className="form-group row">
                        <label
                          htmlFor="priority"
                          className="col-sm-1 col-form-label"
                        >
                          Show
                        </label>

                        <div className="" style={{ width: '90px' }}>
                          <select
                            name="show"
                            value={size}
                            className="custom-select"
                            onChange={(e) => changeShow(e)}
                          >
                            <option value="5">5</option>
                            <option value="10" selected>
                              10
                            </option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                          </select>
                        </div>
                        <label
                          htmlFor="priority"
                          className="col-sm-2 col-form-label"
                        >
                          Entries
                        </label>
                      </div>
                    </div>
                    <div
                      className="col-12 col-md-6 col-lg-6"
                      style={{ justifyContent: 'end', display: 'flex' }}
                    >
                      {/* <Page currentPage={page} totalPage={listDocumentHistori.totalPages} last={listDocumentHistori.last} first={listDocumentHistori.first} changePage={(page) => changePage(page)}/> */}
                      <Pagination
                        count={totalPage}
                        color="primary"
                        page={page}
                        showFirstButton
                        showLastButton
                        onChange={changePage}
                      />
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>

      <Modal
        isOpen={showModal}
        toggle={handleClose}
        size="lg"
        centered
        onClosed={() => {
          setShowModalApprove(false);
          setItemSelected(null);
        }}
      >
        <ModalHeader
          toggle={handleClose}
          style={{ borderBottom: 'none' }}
        ></ModalHeader>
        <ModalBody id="documenttestApp.document.delete.question">
          <h3 className="text-center">
            Are you sure want to delete{' '}
            <span className="font-weight-bold">"{selectedItem?.title}"</span>{' '}
            announcement ?
          </h3>
          <div className="text-center mt-5">
            <button
              className="btn btn-success mx-2 px-4"
              onClick={() => {
                // deleteFile(selectedItem?.id)
                console.log('Button Yes Approve');
              }}
              style={{ background: '#43936C' }}
              disabled={loadingDelete}
            >
              {loadingDelete ? (
                <Spinner color="#fff" size="sm" className="mr-1" />
              ) : (
                <></>
              )}{' '}
              Yes
            </button>
            <button
              disabled={loadingDelete}
              className="btn btn-secondary mx-2"
              onClick={() => {
                setShowModalApprove(false);
                setItemSelected(null);
              }}
            >
              Cancel
            </button>
          </div>
        </ModalBody>
        <ModalFooter style={{ borderTop: 'none' }}></ModalFooter>
      </Modal>

      <Modal
        isOpen={showModalCompare}
        toggle={handleClose}
        size="xl"
        style={{ marginTop: '5%' }}
        onClosed={() => {
          setShowModalCompare(false);
          setItemSelected(null);
        }}
      >
        <div className="my-5">
          <div className="px-5 pt-4" style={{ height: '100%' }}>
            <div className="row">
              <div className="col-12">
                <div className="row page-title-box">
                  <div className="col-11">
                    <h1 className="mb-0 font-size-28">Compare KTP</h1>
                  </div>
                  <div className="col-1 text-right">
                    <a
                      className="mx-2"
                      onClick={() => {
                        setShowModalCompare(false);
                        setItemSelected(null);
                      }}
                    >
                      <svg
                        width="20px"
                        height="20px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <image href="content/images/common/ic_close.svg" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-5">
                <img className="w-100" src={urlImageKTP} alt="" />
              </div>
              <div className="col-7">
                <div className="row mb-3 align-items-center">
                  <div className="col-4">Nomor Identitas</div>
                  <div className="col-8">
                    <input
                      className="py-2 px-2 rounded w-100"
                      type="text"
                      disabled={true}
                      value={detail?.idNumber}
                    />
                  </div>
                </div>
                <div className="row mb-3 align-items-center">
                  <div className="col-4">Nama Lengkap (Sesuai KTP)</div>
                  <div className="col-8">
                    <input
                      className="py-2 px-2 rounded w-100"
                      type="text"
                      disabled={true}
                      value={detail?.name}
                    />
                  </div>
                </div>
                <div className="row mb-3 align-items-center">
                  <div className="col-4">Tempat / Tgl Lahir</div>
                  <div className="col-8">
                    <input
                      className="py-2 px-2 rounded w-100"
                      type="text"
                      disabled={true}
                      value={
                        detail?.placeOfBirth +
                        ', ' +
                        formatDate(detail?.birthday, 'DD MMM YYYY')
                      }
                    />
                  </div>
                </div>
                <div className="row mb-3 align-items-center">
                  <div className="col-4">Jenis Kelamin</div>
                  <div className="col-8">
                    <input
                      className="py-2 px-2 rounded w-100"
                      type="text"
                      disabled={true}
                      value={detail?.genderName}
                    />
                  </div>
                </div>
                <div className="row mb-3 align-items-center">
                  <div className="col-4">Alamat</div>
                  <div className="col-8">
                    <input
                      className="py-2 px-2 rounded w-100"
                      type="text"
                      disabled={true}
                      value={detail?.address}
                    />
                  </div>
                </div>
                <div className="row mb-3 align-items-center">
                  <div className="col-4">RT/RW</div>
                  <div className="col-8">
                    <input
                      className="py-2 px-2 rounded w-100"
                      type="text"
                      disabled={true}
                      value={detail?.detailRtRw}
                    />
                  </div>
                </div>
                <div className="row mb-3 align-items-center">
                  <div className="col-4">Kel/Desa</div>
                  <div className="col-8">
                    <input
                      className="py-2 px-2 rounded w-100"
                      type="text"
                      disabled={true}
                      value={detail?.village}
                    />
                  </div>
                </div>
                <div className="row mb-3 align-items-center">
                  <div className="col-4">Kecamatan</div>
                  <div className="col-8">
                    <input
                      className="py-2 px-2 rounded w-100"
                      type="text"
                      disabled={true}
                      value={detail?.district}
                    />
                  </div>
                </div>
                <div className="row mb-3 align-items-center">
                  <div className="col-4">Agama</div>
                  <div className="col-8">
                    <input
                      className="py-2 px-2 rounded w-100"
                      type="text"
                      disabled={true}
                      value={detail?.religionName}
                    />
                  </div>
                </div>
                <div className="row mb-3 align-items-center">
                  <div className="col-4">Status Perkawinan</div>
                  <div className="col-8">
                    <input
                      className="py-2 px-2 rounded w-100"
                      type="text"
                      disabled={true}
                      value={detail?.maritalStatusName}
                    />
                  </div>
                </div>
                <div className="row mb-3 align-items-center">
                  <div className="col-4">Pekerjaan</div>
                  <div className="col-8">
                    <input
                      className="py-2 px-2 rounded w-100"
                      type="text"
                      disabled={true}
                      value={detail?.occupationName}
                    />
                  </div>
                </div>
                <div className="row mb-3 align-items-center">
                  <div className="col-4">Kewarganegaraan</div>
                  <div className="col-8">
                    <input
                      className="py-2 px-2 rounded w-100"
                      type="text"
                      disabled={true}
                      value={detail?.nationality}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={showModalApprovalHistory}
        toggle={handleClose}
        size="xl"
        style={{ marginTop: '5%' }}
        onClosed={() => {
          setshowModalApprovalHistory(false);
          setItemSelected(null);
        }}
      >
        <div className="my-5">
          <div className="px-5 pt-4" style={{ height: '100%' }}>
            <div className="row">
              <div className="col-12">
                <div className="row page-title-box">
                  <div className="col-11">
                    <h1 className="mb-0 font-size-28">List Reject Reason</h1>
                  </div>
                  <div className="col-1 text-right">
                    <a
                      style={{ background: '#FFF', cursor: 'pointer' }}
                      className="mx-2"
                      onClick={() => {
                        setshowModalApprovalHistory(false);
                        setItemSelected(null);
                      }}
                    >
                      {' '}
                      <svg
                        width="20px"
                        height="20px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <image href="content/images/common/ic_close.svg" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="row align-items-center mb-3">
              <table className="table table-centered table-nowrap mb-0 table-responsive">
                <thead
                  className=""
                  style={{
                    background: '#fff',
                    borderTopLeftRadius: 30,
                    borderTopRightRadius: 30,
                  }}
                >
                  <tr>
                    <th className="py-4 px-4 text-center">No</th>

                    <th className="px-4 w-25">Reject Name</th>
                    <th
                      className="py-4 px-4"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setSort('status');
                        setAscending(!ascending);
                      }}
                    >
                      <div
                        style={{ display: 'flex', alignItems: 'center' }}
                        className="gap-4"
                      >
                        <span className="mr-1 w-100">Reject Reason Detail</span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className="" style={{ background: '#fff' }}>
                  {listApprovalSelected.map((reason, index) => {
                    console.log('list approval', listApprovalSelected);
                    return (
                      <tr
                        className=""
                        style={{
                          background: '#FFF',
                        }}
                      >
                        <td className="text-center">{index + 1}</td>

                        <td
                          className=""
                          style={{
                            wordBreak: 'break-all',
                            minWidth: '300px',
                            maxWidth: '500px',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          {reason?.rejectReasonName}
                        </td>
                        <td
                          className=""
                          style={{
                            wordBreak: 'break-all',
                            minWidth: '300px',
                            maxWidth: '400px',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          {reason?.rejectReasonDetail}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={showModalReject}
        toggle={handleClose}
        size="xl"
        style={{ marginTop: '5%' }}
        onClosed={() => {
          setShowModalReject(false);
          setItemSelected(null);
        }}
      >
        <div className="my-3">
          <div className="px-5 pt-4" style={{ height: '100%' }}>
            <div className="row">
              <div className="col-12">
                <div className="row page-title-box">
                  <div className="col-11">
                    <h1 className="mb-2 font-size-28">Reject Reason</h1>
                  </div>
                  <div className="col-1 text-right">
                    <a
                      className="mx-2"
                      onClick={() => {
                        setShowModalReject(false);
                        setItemSelected(null);
                      }}
                    >
                      <svg
                        width="20px"
                        height="20px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <image href="content/images/common/ic_close.svg" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="row align-items-center">
              <div className="col-1">
                <Form>
                  <div key={'default-checkbox'} className="">
                    <Form.Check
                      type="checkbox"
                      id={`default-checkbox`}
                      label={''}
                      onChange={(e) => {
                        console.log('Checkbox 2', e.target.checked);
                        if (e.target.checked) {
                          setArrayRejectReason([
                            ...arrayRejectReason,
                            {
                              rejectReasonName: 'Nama Kantor',
                              rejectReasonDetail:
                                'Nama kantor pada registrasi online tidak sesuai/tidak dicantumkan',
                            },
                          ]);
                          console.log(
                            'Data Reject Function',
                            arrayRejectReason?.length
                          );
                          console.log('List reject', arrayRejectReason);
                        } else {
                          console.log('uncheck Nama kantor');
                          removeRejectReason('Nama Kantor');
                        }
                      }}
                      className="font-size-16 font-weight-light text-right"
                    />
                  </div>
                </Form>
              </div>
              <div className="col-5">
                <Accordion defaultActiveKey={['0']} alwaysOpen className="">
                  <Accordion.Item eventKey="1" className="">
                    <Accordion.Header
                      className="mb-1"
                      style={{ background: '#fff' }}
                    >
                      Nama Kantor
                    </Accordion.Header>
                    <Accordion.Body
                      className=""
                      style={{ background: '#f5f5f5' }}
                    >
                      <div className="row align-items-center mb-3">
                        <div className="col-12">
                          Nama kantor pada registrasi online tidak sesuai/tidak
                          dicantumkan
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              <div className="col-1">
                <Form>
                  <div key={'default-checkbox'} className="">
                    <Form.Check
                      type="checkbox"
                      id={`default-checkbox`}
                      label={''}
                      onChange={(e) => {
                        console.log('Checkbox 8', e.target.checked);
                        if (e.target.checked) {
                          setArrayRejectReason([
                            ...arrayRejectReason,
                            {
                              rejectReasonName: 'Tanda Tangan',
                              rejectReasonDetail:
                                'Lampiran TTD tidak sesuai/terpotong/berbeda dengan lampiran KTP',
                            },
                          ]);
                          console.log(
                            'Data Reject Function',
                            arrayRejectReason?.length
                          );
                          console.log('List reject', arrayRejectReason);
                        } else {
                          console.log('uncheck Tanda Tangan');
                          removeRejectReason('Tanda Tangan');
                        }
                      }}
                      className="font-size-16 font-weight-light text-right"
                    />
                  </div>
                </Form>
              </div>
              <div className="col-5">
                <Accordion defaultActiveKey={['0']} alwaysOpen className="">
                  <Accordion.Item eventKey="1" className="">
                    <Accordion.Header
                      className="mb-1"
                      style={{ background: '#fff' }}
                    >
                      Tanda Tangan
                    </Accordion.Header>
                    <Accordion.Body
                      className=""
                      style={{ background: '#f5f5f5' }}
                    >
                      <div className="row align-items-center mb-3">
                        <div className="col-12">
                          Lampiran TTD tidak sesuai/terpotong/berbeda dengan
                          lampiran KTP
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
            <div className="row align-items-center"></div>
            <div className="row align-items-center">
              <div className="col-1">
                <Form>
                  <div key={'default-checkbox'} className="">
                    <Form.Check
                      type="checkbox"
                      id={`default-checkbox`}
                      label={''}
                      onChange={(e) => {
                        console.log('Checkbox 9', e.target.checked);

                        if (e.target.checked) {
                          console.log('Checed');

                          setOthersReason(true);
                        } else {
                          setOthersReason(false);
                          console.log('uncheck Others');
                        }
                      }}
                      className="font-size-16 font-weight-light text-right"
                    />
                  </div>
                </Form>
              </div>
              <div className="col-5">
                <Accordion defaultActiveKey={['0']} alwaysOpen className="">
                  <Accordion.Item eventKey="1" className="">
                    <Accordion.Header
                      className="mb-1"
                      style={{ background: '#fff' }}
                    >
                      Others
                    </Accordion.Header>
                    <Accordion.Body
                      className=""
                      style={{ background: '#f5f5f5' }}
                    >
                      <div className="row align-items-center mb-3">
                        <div className="col-12">
                          <textarea
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            rows={2}
                            placeholder="Input reason"
                            value={message}
                            onChange={handleMessageChange}
                          ></textarea>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
            <div className="text-end mt-5">
              <button
                className="btn btn-primary mx-2 px-4"
                onClick={() => {
                  setShowModalReject(false);
                  setItemSelected(null);
                }}
                disabled={loadingDelete}
              >
                {loadingDelete ? <Spinner size="sm" className="mr-1" /> : <></>}{' '}
                Cancel
              </button>
              <button
                disabled={loadingDelete}
                className="btn btn-danger mx-2"
                style={{ background: '#CB3A31' }}
                onClick={() => {
                  console.log(
                    'Data Reject Function',
                    arrayRejectReason?.length
                  );
                  console.log('Other Reason', othersReason);

                  if (othersReason && message?.length != 0) {
                    setArrayRejectReason([
                      ...arrayRejectReason,
                      {
                        rejectReasonName: 'Others',
                        rejectReasonDetail: message,
                      },
                    ]);
                  } else if (othersReason && message?.length == 0) {
                    toast.error('Input reason in order field', {
                      position: 'top-left',
                      autoClose: 8000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                    return;
                  }
                  if (
                    arrayRejectReason?.length != 0 ||
                    arrayRejectOther?.length != 0
                  ) {
                    if (othersReason == true && message?.length != 0) {
                      // rejectReviewKYC(detail);
                      setOnSubmit(true);
                    } else if (othersReason == false) {
                      // rejectReviewKYC(detail);
                      setOnSubmit(true);
                    } else {
                      toast.error('Error input reason', {
                        position: 'top-left',
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                    }
                  } else {
                    toast.error('Pick reason before reject', {
                      position: 'top-left',
                      autoClose: 8000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  }
                }}
              >
                Confirm Reject
              </button>
            </div>
          </div>
        </div>
        <ModalFooter style={{ borderTop: 'none' }}></ModalFooter>
      </Modal>

      <Modal
        isOpen={showModalApprove}
        toggle={handleClose}
        size="lg"
        centered
        onClosed={() => {
          setShowModalApprove(false);
          setItemSelected(null);
        }}
      >
        <ModalHeader
          toggle={handleClose}
          style={{ borderBottom: 'none' }}
        ></ModalHeader>
        <ModalBody id="documenttestApp.document.delete.question">
          <h3 className="text-center">
            Are you sure want to approve{' '}
            <span className="font-weight-bold">"{detail?.name}"</span> data ?
          </h3>
          <div className="text-center mt-5">
            <button
              className="btn btn-success mx-2 px-4"
              onClick={() => {
                console.log('Data Approve Function');
                approveKYC(detail);
              }}
              disabled={loadingDelete}
            >
              {loadingDelete ? (
                <Spinner color="#fff" size="sm" className="mr-1" />
              ) : (
                <></>
              )}{' '}
              Yes
            </button>
            <button
              disabled={loadingDelete}
              className="btn btn-secondary mx-2"
              onClick={() => {
                setShowModalApprove(false);
                setItemSelected(null);
              }}
            >
              Cancel
            </button>
          </div>
        </ModalBody>
        <ModalFooter style={{ borderTop: 'none' }}></ModalFooter>
      </Modal>

      <Modal
        isOpen={showModalPreviewImage}
        toggle={handleClose}
        size="xl"
        centered
        onClosed={() => {
          setShowModalPreviewImage(false);
          setItemSelected(null);
        }}
      >
        <ModalHeader
          toggle={handleClose}
          style={{ borderBottom: 'none' }}
        ></ModalHeader>
        <ModalBody id="documenttestApp.document.delete.question">
          <Carousel
            variant="dark"
            activeIndex={index}
            onSelect={handleSelect}
            data-bs-interval="false"
            interval={null}
          >
            <Carousel.Item>
              <CustomReactPanZoom
                // image="content/images/ktp-1.jpeg"
                image={urlImageKTP}
                alt="Image alt text"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CustomReactPanZoom
                // image="content/images/npwp-1.jpeg"
                image={urlImageNPWP}
                alt="Image alt text"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CustomReactPanZoom
                // image="content/images/selfie-1.jpeg"
                image={urlImageSelfie}
                alt="Image alt text"
              />
            </Carousel.Item>
            <Carousel.Item>
              <CustomReactPanZoom
                // image="content/images/ttd-1.jpeg"
                image={urlImageTTD}
                alt="Image alt text"
              />
            </Carousel.Item>
          </Carousel>
        </ModalBody>
        <ModalFooter style={{ borderTop: 'none' }}></ModalFooter>
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ document }: IRootState) => ({});

const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeDetail);
